body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*font-size: 14px;*/
}

.App {
  text-align: center;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #FCFCFF;
}

@keyframes slideLeft {
  0% { transform: translateX(0); }
  100% { transform: translateX(-10px); }
}

@keyframes slideRight {
  0% { transform: translateX(-10px); }
  100% { transform: translateX(0); }
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}


/* Global file. Properties added/removed will impact every app screen. Remember to use css syntax vs css-in-js syntax */
